body {
  background-color: #0E161B;
}

* {
  font-family: 'Hind', sans-serif;
}

.tit {
  font-family: 'Righteous', sans-serif;
  letter-spacing: 1px;

}