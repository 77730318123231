.primary-button {
    background: linear-gradient(to top right, #31aab8, #be25b9);
    box-shadow: 0px 0px 30px rgba(11, 153, 224, 0.4);
    border: none;
    border-radius: 50px;
    color: white;
    padding: 14px 30px;
    cursor: pointer;
    transition: 100ms;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-size: 16px;
    font-family: 'Righteous', sans-serif;
}

.secondary-button {
    background: linear-gradient(to top right, #31aab8, #0e6f79);
    box-shadow: 0px 0px 24px rgba(11, 153, 224, 0.3);
    border: none;
    border-radius: 20px;
    color: white;
    padding: 8px 20px;
    cursor: pointer;
    transition: background-color 300ms;
    font-weight: bold;
    font-size: 15px;
}

.secondary-button:hover {
    background-color: rgb(42, 47, 147);
}